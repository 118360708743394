<!--
 * @Description: 商城、退款退货原因
 * @Author: 琢磨先生
 * @Date: 2022-08-15 22:46:50
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-12-02 09:27:17
-->

<template>
  <el-card class="box">
    <el-button type="primary" size="small" @click="onEdit()" round icon="plus">新增</el-button>
  </el-card>
  <el-card class="box data">
    <el-table v-loading="loading" :data="tableData" :border="true" row-key="id" default-expand-all>
      <el-table-column label="编号" prop="id" width="100"></el-table-column>
      <el-table-column label="类型" prop="type_text" width="200"></el-table-column>
      <el-table-column label="原因" prop="name"></el-table-column>
      <el-table-column label="停用" width="180">
        <template #default="scope">
          <el-tag type="danger" v-if="scope.row.is_stop">停用</el-tag>
          <el-tag type="success" v-else>正常</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="create_at" width="200"></el-table-column>
      <el-table-column label="操作" width="140">
        <template #default="scope">
          <el-button type="primary" link size="small" @click="onEdit(scope.row)">修改</el-button>
          <el-button type="success" link size="small" @click="onCopy(scope.row)">复制</el-button>
          <el-popconfirm title="去定要删除？" @confirm="onDelete(scope.row)">
            <template #reference>
              <el-button type="danger" link size="small">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
  <el-dialog
    :title="title"
    v-model="visibleDialog"
    width="600px"
    :close-on-click-modal="false"
    draggable
    :close-on-press-escape="false"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="类型" prop="type">
        <el-select v-model="form.type" placeholder>
          <el-option
            :label="item.text"
            :value="item.value"
            v-for="item in enums.refund_causes"
            :key="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="原因" prop="name">
        <el-col :span="16">
          <el-input v-model="form.name" placeholder="请输入原因"></el-input>
        </el-col>
        <!-- <div class="help-block">使用后名称无法修改</div> -->
      </el-form-item>
      <!-- <el-form-item label="颜色" prop="hex_color">
        <el-col :span="8">
          <el-input v-model="form.hex_color" placeholder></el-input>
        </el-col>
        <div class="help-block">带#号16进制颜色值，例如：#ffffff</div>
      </el-form-item>-->
      <el-form-item label="停用">
        <el-switch v-model="form.is_stop"></el-switch>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button type="primary" @click="onSubmit" :loading="saving">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import common_api from "@/http/common_api";
export default {
  data() {
    return {
      loading: false,
      saving: false,
      visibleDialog: false,
      enums: {},
      title: "",
      tableData: [],
      form: {},
      rules: {
        type: [{ required: true, message: "必选", trigger: "blur" }],
        name: [{ required: true, message: "请输入", trigger: "blur" }],
      },
    };
  },
  created() {
    common_api.get_enums().then((res) => {
      if (res.code == 0) {
        this.enums = res.data;
      }
    });

    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      this.current = null;
      this.$http.post(`admin/v1/refundCause`).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
        this.loading = false;
      });
    },

    /**
     *
     */
    onEdit(item) {
      this.title = item ? "修改" : "新增";
      this.form = {};
      this.form = Object.assign({}, item);
      this.visibleDialog = true;
    },
    /**
     * 复制
     */
    onCopy(item) {
      this.title = "新增";
      this.form = {
        // type:item.type,
        name:item.name,
      };
      this.visibleDialog = true;
    },
    /**
     *
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("/admin/v1/refundCause/edit", this.form)
            .then((res) => {
              this.saving = false;
              if (res.code == 0) {
                this.$message.success(res.msg);
                this.loadData();
                this.visibleDialog = false;
              }
            })
            .cath(() => {
              this.saving = false;
            });
        }
      });
    },
    /**
     * 删除
     * @param {*} item
     */
    onDelete(item) {
      this.$http.get("/admin/v1/refundCause/del?id=" + item.id).then((res) => {
        if (res.code == 0) {
          this.$message({ type: "success", message: res.msg });
          this.loadData();
        }
      });
    },
  },
};
</script>

<style scoped>
.block {
  height: 20px;
  width: 20px;
  margin-right: 5px;
  border-radius: 2px;
}
</style>
